
      import API from "!../../node_modules/.store/style-loader-virtual-41813cd887/package/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/.store/style-loader-virtual-41813cd887/package/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../node_modules/.store/style-loader-virtual-41813cd887/package/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../node_modules/.store/style-loader-virtual-41813cd887/package/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../node_modules/.store/style-loader-virtual-41813cd887/package/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../node_modules/.store/style-loader-virtual-41813cd887/package/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../node_modules/.store/css-loader-virtual-d40c7f0320/package/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../node_modules/.store/sass-loader-virtual-de15caef4d/package/dist/cjs.js??ruleSet[1].rules[2].use[2]!./corporate.sass";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../node_modules/.store/css-loader-virtual-d40c7f0320/package/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../node_modules/.store/sass-loader-virtual-de15caef4d/package/dist/cjs.js??ruleSet[1].rules[2].use[2]!./corporate.sass";
       export default content && content.locals ? content.locals : undefined;
